<template>
	<div>
		<!-- Full screen takeover -->
		<div v-if="active && campaignId">
			<CloDialog id="fullscreenAd" @close="hide" class="Dialog--ad" v-if="!minimize">
				<template #body>
					<a v-if="campaignId === 'spanish'" href="/bow/content/language-en" @click="hide">
						<img :src="images.spanish[0]" alt="Now available in Spanish" />
					</a>
					<a v-else-if="campaignId === 'taxonomy'" href="/bow/news/2024-taxonomy-update" @click="hide">
						<img :src="images.taxonomy[0]" alt="Just released: The 2024 eBird/Clements Taxonomy" />
					</a>
					<a v-else :id="promo.buttonId" :href="promo.link">
						<img :src="randomImage" alt="Subscribe to Birds of the World" />
					</a>
				</template>
			</CloDialog>
			<div v-if="minimize && campaignId !== 'spanish' && campaignId !== 'taxonomy'" class="FullscreenAd-minimized">
				<span class="FullscreenAd-minimized-text">
					<span>{{ promo.minimizedText }}</span>
					<a v-if="!accountPreview" href="?login">Sign in</a>
					<a href="/bow/subscribe">Learn more</a>
				</span>

				<button @click="minimize = false">
					<CloIcon size="sm" symbol="arrowUp" />
					<span class="is-visuallyHidden">Open</span>
				</button>
			</div>
		</div>
		<div v-else-if="active">
			<CloDialog id="freeAccess" @close="hide" class="Dialog--freeAccess" v-if="!minimize">
				<template #body>
					<div class="freeAccess">
						<div class="content">
							<CloHeading level="1" size="2">{{ messages.heading }}</CloHeading>
							<p class="u-text-3" v-html="interpolateMessage(messages.description, `<strong>${regionInfo.countryName}</strong>`)"></p>
							<div class="buttonGroup">
								<a :href="`${casUrl}/account/create?service=${casService}`" class="Button Button--large Button--highlight">{{ messages.createAccount }}</a>
								<a href="?login" class="Button Button--large Button--hollow">{{ messages.signIn }}</a>
							</div>
							<p class="u-text-3">
								<a href="/bow/support/free-access-countries">{{ messages.countriesWithFreeAccess }}</a>
								<br>
								<a href="/bow/support/logging-into-birds-of-the-world">{{ messages.howToSignIn }}</a>
							</p>
						</div>
						<div class="image">
							<img src="https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2025/01/bow_illustration_birds_and_shadows_small.png" alt="Birds of the World" />
						</div>
					</div>
				</template>
			</CloDialog>
		</div>
	</div>
</template>

<script>
import axios from 'axios';

import CloHeading from '@/base/CloHeading/CloHeading.vue';
import CloIcon from '@/icons/CloIcon/CloIcon.vue';
import CloDialog from '@/base/CloDialog/CloDialog.vue';
export default {
	components: {
		CloHeading,
		CloIcon,
		CloDialog,
	},
	data() {
		return {
			active: false,
			minimize: false,
			freeAccess: false,
			regionInfo: {
				accessAllowed: false,
				countryName: ''
			},
			images: {
				gbd: [],
				obd: [],
				gbbc: ['https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2025/02/BOW-GBBC-2025.png'],
				default: [
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-White-Tern.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Laughingthrush.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Honey-Buzzard.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Plover.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Puffin.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Western-Gull.png',
					'https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/02/BOW-Ad-Buffy-Fish-Owl.png',
				],
				freetrial: ['https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2025/02/BOW-Free-Trial-2025.png'],
				spanish: ['https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/10/Spanish-Internal-Ad.png'],
				taxonomy: ['https://is-headless-wordpress-prod-s3.s3.amazonaws.com/wp-content/uploads/sites/4/2024/10/Taxonomy-2024-square-map.png']
			},
		};
	},
	props: {
		campaignId: {
			type: String,
			default: null, // warbler, lark, gbd
		},
		accountPreview: {
			type: Boolean,
			default: false,
		},
		loggedIn: {
			type: Boolean,
			default: false,
		},
		messages: {
			type: Object,
			required: true,
		},
		casUrl: {
			type: String,
			required: true
		},
		casService: {
			type: String,
			required: true
		},
		account: {
			type: Boolean,
			default: false,
		}
	},
	computed: {
		cookieName() {
			return 'bow_' + (this.campaignId ? this.campaignId : 'freeAccess');
		},
		delay() {
			return this.campaignId ? 5 : 2; // 5 seconds for campaigns, 2 for free access
		},
		randomImage() {
			// TODO: change when GBBC 24 is over
			const images = this.images[this.campaignId];
			//const images = this.images.default;
			return images[Math.floor(Math.random() * images.length)];
		},
		promo() {
			let promo = {
				buttonId: 'bow_ad_account_default',
				couponCode: '',
				minimizedText: 'This content is available exclusively to Birds of the World Subscribers.',
				link: 'https://birdsoftheworld.org/bow/subscribe?utm_campaign=9483935-BOW%20Spring%20Promotions%202025&utm_source=lightbox&utm_medium=lightbox&utm_content=BOW10&couponCode=BOW10',
			};
			if (this.campaignId === 'freetrial') {
				promo.buttonId = 'bow_ad_account_special';
				promo.link = 'https://hubs.la/Q033z_jQ0';
			}
			if (this.campaignId === 'gbbc') {
				promo.couponCode = 'GBBC25';
				promo.buttonId = 'bow_ad_account_special';
				promo.minimizedText = 'Enjoy complimentary access to Birds of the World during the Great Backyard Bird Count.';
				promo.link = 'https://hubs.la/Q033B0MB0';
			}
			if (this.accountPreview) {
				promo.minimizedText = 'Enjoy complimentary access to this Birds of the World species account.';
			}
			return promo;
		},
	},
	async mounted() {
		if (this.loggedIn) {
			// show a message to logged in users
			if (this.campaignId === 'spanish' || this.campaignId === 'taxonomy') {
				this.show()
			}
		} else {
			// not logged in
			// check free access
			await this.checkFreeAccess()
			if (this.regionInfo.accessAllowed) {
				this.freeAccess = true;
				// only show free access message, even if another campaign is ongoing, they'll see it when they sign in
				this.campaignId = null;
				this.togglePageMessages();
			}
			if (this.freeAccess) {
				this.show()
			} else {
				if (this.campaignId) {
					if (this.campaignId !== 'default' && this.campaignId !== 'freetrial' || this.account) {
						this.show();
					}
				}
			}
			
		}
	},
	methods: {
		async checkFreeAccess() {
			try {
				const { data } = await axios.get('/bow/api/v1/location-access/BOW')
				this.regionInfo = data
			}
			catch (error) {
				if (error.message) {
					console.error(error.message)
				}
			}
		},
		show() {
			// show minimized if cookie is valid
			if (document.cookie.split('; ').find((row) => row.startsWith(this.cookieName))) {
				this.minimize = true;
			}
			// animate in after delay
			setTimeout(() => {
				this.active = true;
			}, this.delay * 1000);
		},
		hide() {
			this.minimize = true;
			let date = new Date();
			let expiration = 1000 * 60 * 30; // 30 minutes
			if (this.freeAccess) {
				expiration = 1000 * 60 * 60 * 24; // 1 day
			}
			if (this.campaignId === 'spanish') {
				expiration = 1000 * 60 * 60 * 24 * 7; // 7 days
			}
			if (this.campaignId === 'taxonomy') {
				expiration = 1000 * 60 * 60 * 24 * 365; // 1 year
			}
			date.setTime(date.getTime() + expiration);
			date = date.toUTCString();
			document.cookie = `${this.cookieName}=; expires=${date}; path=/; SameSite=None; Secure`;
		},
		interpolateMessage(message, value) {
			return message.replace('{0}', value);
		},
		togglePageMessages() {
			// When Free-access is available, dynamically hide/show/change various buttons and other elements on pages
			const exclusiveContent = document.getElementById('exclusiveContent');
			const freeAccessMessage = document.getElementById('freeAccessMessage');
			const freeAccessDescription = document.getElementById('freeAccessDescription');
			if (exclusiveContent) {
				exclusiveContent.style.display = 'none';
			}
			if (freeAccessMessage) {
				freeAccessMessage.style.display = 'block';
			}
			if (freeAccessDescription) {
				freeAccessDescription.innerHTML = freeAccessDescription.textContent.replace('{0}', `<strong>${this.regionInfo.countryName}</strong>`);
			}
			const subscribeButton = document.getElementById('subscribeButton');
			const signInButton = document.getElementById('signInButton');
			if (subscribeButton) {
				subscribeButton.style.display = 'none';
			}
			if (signInButton) {
				signInButton.textContent = this.messages.signInForFreeAccess
			}
		}
	},
};
</script>

<style lang="scss">
.Dialog--ad {
	.Dialog-container {
		outline: 0;
		padding: 0;
		background-color: transparent;
		box-shadow: none;
		position: relative;
		max-width: calc(100vh - 0.5rem);
		max-height: calc(100vw - 0.5rem);
		@include breakpoint(xlarge) {
			max-width: min(900px, calc(100vh - 3rem));
			max-height: min(900px, calc(100vw - 3rem));
		}
	}
	.Dialog-container-close {
		top: -$space-md;
		@include breakpoint(medium) {
			right: $space-sm;
		}
		.Icon {
			fill: $color-white;
		}
	}
	.Dialog-container-body {
		padding: 2rem 1rem;
		@include breakpoint(medium) {
			padding: 2rem;
		}
		overflow-y: visible;
		display: grid;
		align-items: center;
		justify-content: center;
		text-align: center;
		img {
			max-width: 100%;
			max-height: 100%;
			width: auto;
			height: auto;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
		}
	}
}
.FullscreenAd-minimized {
	z-index: 1001;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	@include inset-md;
	@include text(3);
	@include colorReverse;
	color: $color-white;
	background-color: $color-primary-5;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: $space-md;
}
.FullscreenAd-minimized-text {
	display: flex;
	gap: $space-sm $space-md;
	flex-wrap: wrap;
}
.Dialog--freeAccess {
	.Dialog-container {
		padding: 0;
		outline: 0;
		overflow: hidden;
		border-radius: 6px;
		.freeAccess {
			display: flex;
			flex-wrap: nowrap;
			.content {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: $space-sm;
				@include inset-md;
				@include breakpoint(medium) {
					background-color: $brand-color-sand-tint-1;
					padding: $space-xl $space-lg;
				}
				.buttonGroup {
					display: flex;
					gap: $space-md;
				}
			}
			.image {
				display: none;
				@include breakpoint(medium) {
					@include inset-md;
					display: grid;
					align-items: center;
					justify-content: center;
					flex: 1;
					background-color: $brand-color-ocean-blue;
					img {
						width: 100%;
						height: auto;
					}
				}
			}
		}
	}
	.Dialog-container-close {
		.Icon {
			fill: $color-white;
		}
	}
}
</style>
